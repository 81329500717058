:root {
  --default-color: #3e4b8a;
}
.lists {
  display: none;
  transition: 1s all linear;
}
.dropd:focus .lists {
  display: block;
}
.sidebar-scroll {
  overflow-y: scroll;
  max-height: 100vh;
}
.sidebar-scroll::-webkit-scrollbar {
  display: none;
}
.submenu {
  display: none;
}
/* li:hover .submenu {
  display: block;
  color: #3e4b8a;
  text-decoration: none;
}
.submenu li {
  list-style-type: none;
  text-decoration: none;
} */
/* @media screen and (min-width: 1280px) {
  .sidebar {
    -moz-box-shadow: 0 5px 10px 0px var(--box-shadow);
    -webkit-box-shadow: 0 5px 10px 0px var(--box-shadow);
    box-shadow: 0 5px 10px 0px var(--box-shadow);
    left: -350px;
  }
} */

input[type="checkbox"] {
  accent-color: var(--default-color) !important;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
input[type="checkbox"]:hover {
  color: var(--default-color) !important;
}
.show_button {
  display: block;
  background-color: white;
  color: var(--default-color);
  border: none;
  font-size: 20px;
}
@media screen and (min-width: 1270px) {
  .show_button {
    display: none;
  }
}
.toggle_sidebar_button {
  z-index: 999;
  margin-top: 23px;
  margin-left: 51px;
  position: fixed;
}

/* form validation css */

.hospitalerrorr {
  color: red;
  margin-left: 150px;
  margin-top: -15px;
  font-size: 13px;
}
/* .loginerrorr {
  color: red;
  margin-left: -180px;
  margin-top: -15px;
  font-size: 13px;
} */
.active_color {
  color: var(--default-color) !important;
}
.active {
  color: var(--default-color) !important;
}
.btn-close {
  border: #1b1b1c !important;
  /* background: grey !important; */
}
.edit_hospital {
  display: flex;
  color: red;
  margin-left: 14px;
  margin-top: -18px;
  font-size: 13px;
  margin-bottom: 5px;
}
/* @media screen and (max-width: 767px) {
  .edit_hospital {
    color: red;
    margin-left: 10px;
    margin-top: -15px;
    font-size: 12px;
    margin-bottom: 0px;
  }
} */
.btn_bg_color {
  background-color: var(--default-color) !important;
  border: 0;
}
.sidebar_border {
  border-right: 1px solid var(--default-color) !important;
  box-shadow: 2px 0px 2px 1px var(--default-color) !important;
}
.scroll_sidebar {
  overflow: scroll !important;
}
.scroll_sidebar::-webkit-scrollbar {
  display: none !important;
}
.roleedir_err {
  color: red;
  margin-left: 120px;
  margin-top: -15px;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .roleedir_err {
    color: red;
    margin-top: -15px;
    font-size: 12px;
    margin-left: 0px;
  }
}
.useredit_err {
  color: red;
  margin-bottom: 8px;
  font-size: 13px;
  margin-top: -15px;
  margin-left: 125px;
}
@media screen and (max-width: 767px) {
  .useredit_err {
    color: red;
    margin-top: -15px;
    font-size: 12px;
    margin-left: 0px;
  }
}
@media screen and (min-width: 766px) {
  .user_editmodal {
    margin-left: 50px !important;
  }
}
.edit_appointment {
  color: red;
}
@media screen and (min-width: 766px) {
  .edit_appointment {
    color: red;
    margin-top: 0px;
    font-size: 13px;
    margin-left: 50px;
  }
}
.edit_patient {
  color: red;
  margin-top: -40px;
  font-size: 13px;
  margin-left: 72px;
}
@media screen and (max-width: 575px) {
  .edit_patient {
    margin-top: -18px;
    margin-left: 2px;
  }
}

.edit_ledger {
  color: red;
  font-size: 13px;
}
@media screen and (min-width: 766px) {
  .edit_ledger {
    margin-top: -17px;
    margin-left: 122px;
  }
}
.edit_collection {
  color: red;
  margin-left: 118px;
  margin-top: -20px;
  font-size: 13px;
}
@media screen and (max-width: 767px) {
  .edit_collection {
    color: red;
    margin-left: 10px;
    margin-top: -15px;
    font-size: 12px;
    margin-bottom: 0px;
  }
}

/* loader */

.loader {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid var(--default-color); /* Blue border for animation */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-top: 50px;
  animation: spin 1.5s linear infinite; /* Animation properties */
   /* Center the loader */
  position: absolute; /* Positioning context for centering */
   /* Position the loader at the bottom */
  left: 40%; /* Move the loader 50% from the left */
  transform: translateX(-50%); /* Center the loader horizontally */
}

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /* userverification */
.verification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  margin-top: 10%;
  padding: 35px;
  width: 500px;
  top: 10;
  left: 50%;
  margin-left: 30%;
  border-radius: 25px;
}

 
.App {
  text-align: center;
}
 
.otp-input {
  font-size: 24px;
  border: solid 2px #bbb;
  margin-right: 10px;
}
 
.otp-input:last-child {
  margin-right: 0;
}
 
.btn-container {
  margin-top: 20px;
}
.btn-container button {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 15px;
}
.btn-container button:hover {
  cursor: pointer;
  background-color: #3e4b8a !important;
  color:white
}

.nav_focus:hover {
  /* background-color: rgb(247, 240, 240)!important; */
  cursor:pointer !important;
}
